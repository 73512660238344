<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <img :src="userInfo.photo" alt="" style="width: 100%;">
                        <hr>
                        <form 
                            class="form-horizontal"
                            role="form"
                            action="/user/account/update/photo/"
                            method="post"
                            enctype="multipart/form-data"
                            style="text-align: center;"
                        >
                            <label class="btn btn-default btn-file">
                                更新头像
                                <input type="file" @change="handleFileChange" accept="image/*" style="display: none;" name="photo" required="">
                            </label>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header">
                        <span style="font-size: 130%">个人信息</span>
                        <hr>
                        <form class="info-form">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-2 col-form-label">用户名：</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="userInfo.username">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword3" class="col-sm-2 col-form-label">天梯分：</label>
                                <div class="col-sm-10">
                                    <input type="text" disabled class="form-control" :value="userInfo.rating">
                                </div>
                            </div>
                            <div class="error-message">{{ error_message }}</div>
                            <button @click="updateInfo" type="button" class="btn btn-success" style="border-radius: 5px">更新信息</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore();
        const file = ref(null);
        const userInfo = ref([]);
        let error_message = ref('');

        const refresh_user = () => {
            store.dispatch("getinfo", {
                success: (resp) => {
                    userInfo.value = resp;
                }
            });
        }
        
        refresh_user();

        const handleFileChange = (event) => {
            file.value = event.target.files[0];
            handleSubmit();
        };

        const handleSubmit = () => {
            if (!file.value) {
                alert("请先选择一个文件!");
                return;
            }

            const formData = new FormData();
            formData.append('file', file.value);

            $.ajax({
                url: 'https://snake.iffyoo.cn/api/user/account/update/photo/',
                type: 'post',
                data: formData,
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                contentType: false,
                processData: false,
                success(resp) {
                    if (resp.error_message === "success") {
                        error_message.value = '';
                        refresh_user();
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
                error(resp) {
                    console.error('Error:', resp);
                    // 处理错误响应
                }
            });
        };

        const updateInfo = () => {
            $.ajax({
                url: "https://snake.iffyoo.cn/api/user/account/update/info/",
                type: "post",
                data: {
                    username: userInfo.value.username
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        error_message.value = '';
                        refresh_user();
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
            });
        }

        return {
            userInfo,
            error_message,
            handleFileChange,
            handleSubmit,
            updateInfo
        }
    }
}
</script>

<style scoped>
div.error-message {
    color: red;
}

.btn-file {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    margin-bottom: 10px;
}

.info-form {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

.row {
    margin-bottom: 20px;
}
</style>
