<template>
   <div class="result-board">
      <div class="result-board-text" v-if="$store.state.pk.loser === 'all'">
         Draw
      </div>
      <div class="result-board-text" v-else-if="$store.state.pk.loser === 'A' && $store.state.pk.a_id === parseInt( $store.state.user.id)">
         Lose
      </div>
      <div class="result-board-text" v-else-if="$store.state.pk.loser === 'B' && $store.state.pk.b_id === parseInt( $store.state.user.id)">
         Lose
      </div>
      <div class="result-board-text" v-else>
         Win
      </div>
      <div class="result-board-btn">
         <button @click="restart" type="button" class="btn btn-warning btn-lg">
            再来！
         </button>
      </div>
   </div>
</template>

<script>
import { useStore } from "vuex";

export default {
   setup() {
      const store = useStore();

      const restart = () => {
         store.commit("updateStatus", "matching");
         store.commit("updateLoser", "none");
         store.commit("updateOpponent", {
            username: "我的对手",
            photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
         })
      }

      return {
         restart
      };
   }
}

</script>

<style lang="less" scoped>
.result-board {
   position: absolute;
   top: 30vh;
   left: 35vw;
   width: 30vw;
   height: 30vh;;
   background-color: rgba(50, 50, 50, .5);
}

.result-board-text {
   color: #fff;
   font-size: 50px;
   font-weight: 600;
   font-style: italic;
   text-align: center;
   padding-top: 5vh;
}

.result-board-btn {
   text-align: center;
   padding-top: 4vh;
}
</style>