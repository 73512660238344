<template>
   <PlayGround />
</template>

<script>
import PlayGround from '@/components/PlayGround'

export default {
   components: {
       PlayGround,
   },
   setup() {
    
   }
}
</script>

<style lang="less" scoped>

</style>