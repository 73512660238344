<template>
   <ContentField>
        404 not found
   </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField'

export default {
    name: "PkIndexView",
    data() {
        return {

        }
    },
    created() {

    },
    components: {
        ContentField
    }
}
</script>

<style lang="less" scoped>

</style>