<template>
    <ContentField>
         <table class="table table-striped table-hover">
             <thead>
                 <tr style="text-align: center;">
                     <th>玩家</th>
                     <th>天梯分</th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for="user in users" :key="user.id" style="text-align: center;">
                     <td>
                         <img :src="user.photo" alt="" class="record-user-photo">
                         &nbsp;
                         <span class="record-user-username">{{ user.username }}</span>
                     </td>
                     <td>{{ user.rating }}</td>
                 </tr>
             </tbody>
         </table>
         <nav>
             <ul class="pagination" style="float: right;">
                 <li class="page-item" @click="click_page(-2)">
                     <a class="page-link">前一页</a>
                 </li>
                 <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"  @click="click_page(page.number)">
                     <a class="page-link" href="#">{{ page.number }}</a>
                 </li>
                 <li class="page-item" @click="click_page(-1)">
                     <a class="page-link" href="#">后一页</a>
                 </li>
             </ul>
         </nav>
    </ContentField>
 </template>
 
 <script>
 import ContentField from "../../components/ContentField";
 import { useStore } from "vuex";
 import { ref } from "vue";
 import $ from "jquery";
 
 export default {
     components: {
         ContentField
     },
     setup() {
         const store = useStore();
         let users = ref([]);
         let current_page = 1;
         let total_users = 0;
         let pages = ref([]);
 
         const click_page = page => {
             if (page === -2) page = current_page - 1;
             else if (page === -1) page = current_page + 1;
             let max_pages = parseInt(Math.ceil(total_users / 10));
 
             if (page >= 1 && page <= max_pages) {
                 pull_page(page);
             }
         }
 
         const update_pages = () => {
             let max_pages = parseInt(Math.ceil(total_users / 10));
             let new_pages = [];
             for (let i = current_page - 2; i <= current_page + 2; i++) {
                 if (i >= 1 && i <= max_pages) {
                     new_pages.push({
                         number: i,
                         is_active: i === current_page ? "active" : ""
                     });
                 }
             }
             pages.value = new_pages;
         }
 
         const pull_page = page => {
             current_page = page;
             $.ajax({
                 url: "https://snake.iffyoo.cn/api/ranklist/getlist/",
                 data: {
                     page,
                 },
                 type: "get",
                 headers: {
                     Authorization: "Bearer " + store.state.user.token,
                 },
                 success(resp) {
                     users.value = resp.users;
                     total_users = resp.users_count;
                     update_pages();
                     // console.log(resp);
                 },
                 error(resp) {
                     // console.log(resp);
                 }
             })
         }
 
        pull_page(current_page)

    
        return {
            users,
            pages,
            click_page
        }
     }
 }
 </script>
 
 <style lang="less" scoped>
 .record-user-photo {
     width: 4vh;
     border-radius: 50%;
 }
 
 .query-btn {
     width: 82px !important;
     height: 30px !important;
     font-size: 14px !important;
     line-height: 16px !important;
 }
 </style>