<template>
  <NavBar />
  <router-view></router-view>
</template>

<script>
import NavBar from './components/NavBar.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"

export default {
  components: {
    NavBar
  }

}

</script>

<style>
  div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-image: url('@/assets/images/background.jpg');
    background-size: cover;
    background-attachment: fixed;
  }
</style>
