<template>
   <div class="container content-field">
        <div class="card">
            <div class="card-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentField",
    data() {
        return {

        }
    },
    created() {

    },
}
</script>

<style lang="less" scoped>
   .content-field {
      margin-top: 20px;
   }
</style>